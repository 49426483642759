import React, {useState, useContext, useEffect, useRef, Fragment} from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import * as AppContext from '../Contexts/AppContext';
import * as Geolocation from '../Contexts/GeolocationContext';
import * as L from '../Utils/Lang';
import {isStockAvailable} from '../Utils/ProductUtil';
import * as SvgIcon from './SvgIcon';
import {Color, fstyles, UnderlineButton} from './Widget';
import {useDimension} from '../Hooks/AppHooks';
import StoreMap from './Map';
import calcDistance from '../Utils/calcDistance';
import StoreItemReserve from './StoreItemReserve';
import FixedRatioImage from './FixedRatioImage';
import ColorDot from './ColorDot';
import BottomSlidePicker from './BottomSlidePicker';

export function ModalContent(props) {
  const {
    close,
    product,
    onReserveBtnClick,
    activeColor,
    setActiveColor,
    activeSize,
    setActiveSize,
    location = null,
    isInApp = false,
    modalSizeRef,
  } = props;
  const [stores, setStores] = useState([]);
  const [activeStoreId, setActiveStoreId] = useState(null);
  const app = useContext(AppContext.Context);
  const breakpoints = AppContext.Actions.getBreakpoints();
  const geolocation = useContext(Geolocation.Context);
  const {dimension} = useDimension();

  useEffect(() => {
    setInternalActiveColor(activeColor);
    setInternalActiveSize(activeSize);
  }, [activeColor, activeSize]);

  // we need internal state because we get active color only once from outside when modal open.
  const [internalActiveColor, setInternalActiveColor] = useState(activeColor);
  const [internalActiveSize, setInternalActiveSize] = useState(activeSize);
  const tabletPickerRef = useRef();

  const tablet = dimension?.innerWidth <= breakpoints.tablet;
  const mobile = dimension?.innerWidth <= breakpoints.mobile;

  useEffect(() => {
    async function getBrandStores() {
      const stores = await AppContext.Actions.getBrandStores();
      setStores(stores);
    }
    getBrandStores();
    if (!isInApp) {
      Geolocation.Actions.askPermisssion();
      Geolocation.Actions.subscribeLocationWatcher();
      return Geolocation.Actions.unsubscribeLocationWatcher();
    }
  }, []);

  const locationData = location ? location : geolocation.curLocation;

  const activeColorData =
    product.groupVariants.colors.find(c => c.color === internalActiveColor) ||
    {};

  const mobilePicker = (
    <Fragment>
      {/* colors */}
      <div
        className="color-area"
        onClick={() =>
          tabletPickerRef.current.open({
            title: '請選擇顏色',
            bottom: L.d(
              product.groupVariants.colors.find(
                c => c.color === internalActiveColor,
              )?.display || '',
            ),
            options: product.groupVariants.colors.map((c, idx) => (
              <ColorDot
                key={idx}
                texture={c.texture}
                color={c.color}
                active={internalActiveColor === c.color}
                onClick={() => {
                  setActiveColor(c.color);
                  setInternalActiveColor(c.color);
                  tabletPickerRef.current.close();
                }}
                style={{
                  marginRight: product.groupVariants.colors.length > 1 ? 16 : 0,
                }}
              />
            )),
          })
        }>
        <ColorDot
          texture={activeColorData.texture}
          color={activeColorData.color}
          active={false}
        />
        <div className="label">
          {L.d(
            product.groupVariants.colors.find(
              c => c.color === internalActiveColor,
            )?.display || '',
          )}
        </div>

        <SvgIcon.DropDown color={Color.mainDark} size={24} />
      </div>

      {/* sizes */}
      <div
        className="size-area"
        onClick={() =>
          tabletPickerRef.current.open({
            title: '請選擇尺寸',
            bottom: (
              <UnderlineButton
                onClick={() => {
                  modalSizeRef.current.open();
                }}>
                尺寸表
              </UnderlineButton>
            ),
            options: product.groupVariants.size_options.map((s, idx) => {
              const available = isStockAvailable(product.groupVariants.stocks, {
                color: internalActiveColor,
                size: s,
              });
              return (
                <SizeBtn
                  breakpoints={app.breakpoints}
                  key={idx}
                  active={internalActiveSize === s}
                  disabled={!available}
                  onClick={() => {
                    available && (setActiveSize(s) || setInternalActiveSize(s));
                    tabletPickerRef.current.close();
                  }}>
                  {L.d(s)}
                </SizeBtn>
              );
            }),
          })
        }>
        <div className="label">尺寸表：{internalActiveSize}</div>

        <SvgIcon.DropDown color={Color.mainDark} size={24} />
      </div>
    </Fragment>
  );

  const picker = (
    <PickerWrapper>
      <div className="color-area">
        <div className="label">
          {L.d(
            product.groupVariants.colors.find(c => c.color === activeColor)
              ?.display || '',
          )}
        </div>
        <div className="colors-wrapper">
          {product.groupVariants.colors.map((c, idx) => (
            <ColorDot
              key={idx}
              texture={c.texture}
              color={c.color}
              style={{marginRight: 16}}
              active={internalActiveColor === c.color}
              onClick={() => {
                setActiveColor(c.color);
                setInternalActiveColor(c.color);
                setActiveSize(null);
                setInternalActiveSize(null);
              }}
            />
          ))}
        </div>
      </div>

      {/* size */}
      <div className="size-area">
        <UnderlineButton
          className="label"
          onClick={() => {
            modalSizeRef.current.open();
          }}>
          尺寸表
        </UnderlineButton>
        <div className="sizes-wrapper">
          {product.groupVariants.size_options.map((s, idx) => {
            const available = isStockAvailable(product.groupVariants.stocks, {
              color: internalActiveColor,
              size: s,
            });
            return (
              <SizeBtn
                breakpoints={app.breakpoints}
                key={idx}
                active={internalActiveSize === s}
                disabled={!available}
                onClick={() => {
                  available && (setActiveSize(s) || setInternalActiveSize(s));
                }}>
                {L.d(s)}
              </SizeBtn>
            );
          })}
        </div>
      </div>
    </PickerWrapper>
  );

  if (tablet) {
    return (
      <OutsideWrapper>
        <TabletModalContentWrapper breakpoints={breakpoints}>
          <div className="header">
            <div className="title">預約保留商品</div>
            <div className="info">與門市服務人員預約，替您保留商品</div>

            <div className="close" onClick={close}>
              <SvgIcon.Cross size={28} color={Color.mainDark} />
            </div>
          </div>
          <div className="content">
            <div className="product">
              <div className="image">
                <FixedRatioImage
                  mode="cover"
                  image={product.images?.[0]?.image}
                  ratio={5 / 4}
                />
              </div>
              <div className="detail">
                <div className="title">{L.d(product.title)}</div>
                <div className="price">NT$ {L.d(product.promote_price)}</div>

                <div style={{flex: 1}} />

                {mobilePicker}
              </div>
            </div>
            <div className="store-items">
              {stores
                .map(s => ({
                  ...s,
                  distance: locationData
                    ? calcDistance(
                        {lat: s.lat, lng: s.lng},
                        {
                          lat: locationData.lat,
                          lng: locationData.lng,
                        },
                      )
                    : null,
                }))
                .sort((a, b) => a.distance - b.distance)
                .map((s, idx) => (
                  <StoreItemReserve
                    onReserveBtnClick={store =>
                      onReserveBtnClick(store, {
                        color: internalActiveColor,
                        size: internalActiveSize,
                      })
                    }
                    key={idx}
                    mobile={mobile}
                    tablet={tablet}
                    storeIdx={idx}
                    store={s}
                    isActive={s.id.toString() === activeStoreId}
                    setActiveStore={id =>
                      setActiveStoreId(id !== null ? id.toString() : null)
                    }
                  />
                ))}
            </div>
          </div>
        </TabletModalContentWrapper>
        <BottomSlidePicker
          getInstance={inst => {
            tabletPickerRef.current = inst;
          }}
        />
      </OutsideWrapper>
    );
  }

  return (
    <OutsideWrapper>
      <ModalContentWrapper breakpoints={breakpoints}>
        <div className="close" onClick={close}>
          <SvgIcon.Cross size={28} color={Color.mainDark} />
        </div>
        <div className="content">
          <div className="info">
            <div className="product">
              <div className="image">
                <FixedRatioImage
                  mode="cover"
                  image={product.images?.[0]?.image}
                  ratio={5 / 4}
                />
              </div>
              <div className="detail">
                <div className="title">{L.d(product.title)}</div>
                <div className="price">NT$ {L.d(product.promote_price)}</div>

                <div style={{flex: 1}} />
                {picker}
              </div>
            </div>
            <div className="separate-line" />
            <div className="stores">
              <div className="header">與門市服務人員預約，替您保留商品：</div>
              <div className="store-items">
                {stores
                  .map(s => ({
                    ...s,
                    distance: locationData
                      ? calcDistance(
                          {lat: s.lat, lng: s.lng},
                          {
                            lat: locationData.lat,
                            lng: locationData.lng,
                          },
                        )
                      : null,
                  }))
                  .sort((a, b) => a.distance - b.distance)
                  .map((s, idx) => (
                    <StoreItemReserve
                      onReserveBtnClick={store =>
                        onReserveBtnClick(store, {
                          color: internalActiveColor,
                          size: internalActiveSize,
                        })
                      }
                      key={idx}
                      storeIdx={idx}
                      store={s}
                      mobile={mobile}
                      tablet={tablet}
                      isActive={s.id.toString() === activeStoreId}
                      setActiveStore={id =>
                        setActiveStoreId(id !== null ? id.toString() : null)
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="map">
            <StoreMap
              height={'100%'}
              stores={stores}
              activeStoreId={activeStoreId}
              setActiveStoreId={id => {
                setActiveStoreId(id !== null ? id.toString() : null);
              }}
              curLocation={geolocation.curLocation || null}
              hideFullScreenController
              isInApp={false}
            />
          </div>
        </div>
      </ModalContentWrapper>
    </OutsideWrapper>
  );
}

function Modal(props) {
  return (
    <SlideInPanel
      position="bottom-to-center"
      size={300}
      dismissOnClickBackdrop={false}
      style={{backgroundColor: 'transparent'}}
      getInstance={inst => {
        props.getInstance({
          open: async ({
            product,
            activeColor,
            setActiveColor,
            activeSize,
            setActiveSize,
            modalSizeRef,
          }) => {
            inst.open(
              <ModalContent
                onReserveBtnClick={(store, params) =>
                  window.open(
                    `https://LIVE_CHAT_URL?store=${
                      store.id
                    }&params=${JSON.stringify(params)}`,
                  )
                }
                activeColor={activeColor}
                setActiveColor={setActiveColor}
                activeSize={activeSize}
                setActiveSize={setActiveSize}
                product={product}
                close={inst.close.bind(inst)}
                modalSizeRef={modalSizeRef}
              />,
            );
          },
          close: () => {
            inst.close();
          },
        });
      }}
    />
  );
}

const SizeBtn = styled.div`
  ${fstyles.text14};
  min-width: 48px;
  height: 32px;
  border: 1px solid ${Color.mainDark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props =>
    props.disabled ? `text-decoration: line-through; color: #b8b8b8` : ''};
  border-width: 1px;
  border-style: solid;
  ${props =>
    props.active
      ? `border-color: ${Color.mainDark};`
      : 'border-color: transparent;'}

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    min-width: 48px;
  }
`;

const OutsideWrapper = styled.div``;

const TabletModalContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #fff;

  & > .header {
    padding: 14px 0px;
    position: absolute;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .title {
      color: ${Color.mainDark};
      font-weight: 500;
      ${fstyles.text16}
    }

    & > .info {
      color: #cca75c;
      font-weight: 500;
      margin-top: 5px;
      ${fstyles.text12}
    }
  }

  & > .content {
    padding-top: 78px;
    height: 100%;
    padding: 78px 20px 20px;
    overflow: auto;

    & > .product {
      padding: 12px 0px 24px;
      border-bottom: 1px solid ${Color.mainDark_10};
      display: flex;
      & > .image {
        width: 144px;
        flex-shrink: 0;
        margin-right: 12px;
      }
      & > .detail {
        display: flex;
        flex-direction: column;
        & > .title {
          font-weight: bold;
          ${fstyles.text14}
        }
        & > .price {
          ${fstyles.text14}
        }

        & > .color-area {
          display: flex;
          align-items: center;
          cursor: pointer;

          & > .label {
            font-weight: bold;
            color: ${Color.mainDark};
            margin-left: 8px;
            margin-right: 4px;
            ${fstyles.text14}
          }
        }

        & > .size-area {
          margin-top: 15px;
          display: flex;
          align-items: center;
          cursor: pointer;

          & > .label {
            margin-right: 4px;
            color: ${Color.mainDark};
            ${fstyles.text14}
          }
        }
      }

      & > .store-items {
      }
    }
  }

  & .close {
    position: absolute;
    right: 16px;
    top: 12px;
    z-index: 100;
    cursor: pointer;
  }
`;

const ModalContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  overflow: auto;
  position: relative;

  & .close {
    background-color: #fff;
    padding: 8px;
    position: absolute;
    right: 20px;
    top: 20px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    z-index: 100;
  }

  & > .content {
    display: flex;
    height: 100%;

    & > .info {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;

      & > .product {
        display: flex;
        padding: 40px 0px 24px 50px;

        & > .image {
          width: 176px;
        }

        & > .detail {
          padding-left: 16px;
          display: flex;
          flex-direction: column;

          & > .title {
            font-weight: 500;
            ${fstyles.text14}
          }
          & > .price {
            margin-top: 2px;
          }
        }
      }

      & > .stores {
        display: flex;
        flex-direction: column;
        overflow: auto;

        & > .header {
          padding: 24px 50px 8px;
          color: ${Color.mainDark};
          ${fstyles.text20}
        }

        & > .store-items {
          overflow: auto;
          height: 100%;
          padding: 0px 50px;
        }
      }

      & > .separate-line {
        height: 1px;
        flex-shrink: 0;
        width: calc(100% - 100px);
        margin: 0px 50px;
        background-color: ${Color.mainDark_10};
      }
    }

    & > .map {
      width: 50%;
      height: 100%;
    }
  }
`;

const PickerWrapper = styled.div`
  & > .color-area {
    padding: 24px 0px;
    & > .label {
      margin-bottom: 16px;
      ${fstyles.text14}
    }
    & > .colors-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  & > .size-area {
    padding-bottom: 20px;
    & > .label {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      ${fstyles.text14}

      & > .error {
        color: #e22828;
        margin-left: 22px;
        ${fstyles.text14}
      }
    }
    & > .sizes-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
`;

export default Modal;
