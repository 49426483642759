import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';
import * as SvgIcon from './SvgIcon';
import {Color, UnderlineButton} from './Widget';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';
import StoreMap from './Map';

const dayDisplayMap = {
  1: 'store__day_mon',
  2: 'store__day_tue',
  3: 'store__day_wed',
  4: 'store__day_thu',
  5: 'store__day_fri',
  6: 'store__day_sat',
  7: 'store__day_sun',
};

function StoreItemReserve(props) {
  const {
    mobile = false,
    tablet = false,
    onReserveBtnClick = () => 0,
    store,
    storeIdx,
    isActive,
    setActiveStore,
  } = props;
  const [expand, setExpand] = React.useState(false);

  if (!store) {
    return null;
  }

  return (
    <Wrapper
      isActive={isActive}
      mobile={mobile}
      tablet={tablet}
      onClick={() => {
        setActiveStore(isActive ? null : store.id);
      }}>
      {!tablet && (
        <Widget.FlexCenter
          style={{
            backgroundColor: Color.mainDark,
            width: 32,
            height: 32,
            borderRadius: '50%',
            color: 'white',
            marginRight: 20,
          }}>
          {storeIdx + 1}
        </Widget.FlexCenter>
      )}
      <div className="info" style={{marginLeft: mobile ? 0 : 20}}>
        <div
          style={{
            flex: 1,
            marginRight: 10,
            alignSelf: 'stretch',
          }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              lineHeight: '24px',
              color: Color.mainDark,
            }}>
            {L.d(store.name)}{' '}
            {store.distance !== null && (
              <span
                style={{paddingLeft: 8, fontWeight: 'normal', fontSize: 14}}>
                {Math.round(store.distance * 10) / 10} km
              </span>
            )}
          </div>

          <div style={{marginBottom: 8}}>{L.d(store.address) || ''}</div>

          <Widget.FlexRow
            onClick={evt => {
              evt.stopPropagation();
              setExpand(!expand);
            }}>
            <div
              style={{
                color: Color.mainDark_70,
                marginRight: 10,
                fontSize: 14,
                lineHeight: '22px',
              }}>
              {'門市資訊'}
            </div>
            {expand ? (
              <SvgIcon.Minus w={20} h={20} />
            ) : (
              <SvgIcon.Plus w={20} h={20} />
            )}
          </Widget.FlexRow>

          {expand && (
            <div style={{marginTop: 16, flex: 1}}>
              <UnderlineButton
                style={{marginBottom: 12}}
                onClick={e => {
                  e.stopPropagation();
                  window.open(`tel:${store.tel}`, '_self');
                }}>
                {store.tel}
              </UnderlineButton>
              <div>營業時間</div>

              <div
                style={{
                  marginBottom: 12,
                  whiteSpace: 'pre-wrap',
                  fontSize: 14,
                  color: Color.mainDark,
                }}>
                {store.openTime.map((ot, idx) => (
                  <div key={idx}>{`${L.s(dayDisplayMap[idx + 1])} ${ot[0]}-${
                    ot[1]
                  }`}</div>
                ))}
              </div>
              <div style={{backgroundColor: '#ccc', height: 100, flex: 1}}>
                <StoreMap
                  height="100%"
                  stores={[store]}
                  activeStoreId={store.id}
                  showMarkerIdx={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <RectButton
        onClick={() => {
          onReserveBtnClick(store);
        }}
        skin={BUTTON_SKIN.PRIMARY}
        text="預約"
        size={BUTTON_SIZE.MEDIUM}
        style={{
          width: mobile ? '100%' : '160px',
          marginTop: mobile ? 16 : 0,
          marginRight: mobile ? 0 : 20,
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.mobile ? 'column' : 'row')};
  align-items: ${props => (props.mobile ? 'stretch' : 'flex-start')};
  font-size: 14px;
  line-height: 22px;
  color: ${Color.mainDark};
  padding: ${props => (props.mobile ? '20px 0px' : '25px 0px')};
  border-bottom: 1px solid ${Color.mainDark_10};
  ${props =>
    props.isActive && !props.tablet
      ? `background-color: rgba(20, 20, 20, 0.03);`
      : ''}

  & > .info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export default StoreItemReserve;
