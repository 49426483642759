import React from 'react';
import styled from 'styled-components';
import {Color} from './Widget';

function ColorDot(props) {
  const {texture, color, active, style = {}, onClick = () => 0} = props;
  return (
    <Wrapper
      texture={texture}
      color={color}
      active={active}
      onClick={onClick}
      style={style}
    />
  );
}

const Wrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  ${props =>
    props.active
      ? `border-color: ${Color.mainDark};`
      : 'border-color: transparent;'}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;

    ${props =>
      props.texture
        ? `background-image: url(${props.texture});background-size: cover;`
        : ''}
    background-color: ${props => props.color};
    border: 1px solid ${Color.mainDark_10};
    border-radius: 50%;
  }
`;

export default ColorDot;
