import React, {
  createRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {Actions, Context, NavActions} from '../Contexts/AppContext';
import {useDimension} from '../Hooks/AppHooks';
import {
  Color,
  FlexColCenter,
  FlexRowCenter,
  fstyles,
  UnderlineButton,
} from '../Components/Widget';
import * as L from '../Utils/Lang';
import * as SvgIcon from '../Components/SvgIcon';
import ScrollTo from '../Utils/ScrollTo';
import {
  aggregateProductVariants,
  getFormatCartItems,
  getPriceTag,
  getSummaryCartItems,
  isStockAvailable,
} from '../Utils/ProductUtil';
import CopyToClipboard from '../Components/CopyToClipboard';
import ProductDetailSlider from '../Components/ProductDetailSlider';
import ProductDetailSizeModal from '../Components/ProductDetailSizeModal';
import ProductReserveModal from '../Components/ProductReserveModal';
import ColorDot from '../Components/ColorDot';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../Components/RectButton';
import CustomNav from '../Components/CustomNav';
import {MiniCartIcon} from '../Components/MiniCartIcon';
import Alert from '../Components/Modal/Alert';
import Box from '../images/illustration-empty-box.svg';
import ProductItem from '../Components/ProductItem';
import {brandIdToBrandName, brandsDisplayArr} from '../Domain/Brand';
import AnimProgressCircle from '../Components/AnimProgressCircle';
import {MEMBERSHIP_CONFIG, MEMBERSHIP_LEVEL_DISPLAY} from '../Domain/Member';
import queryString from 'query-string';
import {ChevronLeft} from '../Components/SvgIcon';
import {navigate} from 'gatsby';

const STYLE_WITH_DOM_ID = 'product-detail-style_with';
const getRelatedProductItemWidth = (dimension, breakpoints) => {
  const windowWidth = dimension?.innerWidth || 1200;
  let itemWidth = 300;
  const gutter = 16;
  const desktopPadding = 50;
  if (windowWidth > breakpoints.tablet) {
    itemWidth = (windowWidth - desktopPadding * 2 - gutter * 3) / 4;
  } else if (windowWidth > breakpoints.mobile) {
    itemWidth = 323;
  } else {
    itemWidth = 260;
  }

  return itemWidth;
};

function ProductPage(props) {
  const {location} = props;
  const parsed = queryString.parse(location.search);
  const id = parsed.id;
  const [product, setProduct] = useState(null);
  const {dimension, mobile, tablet} = useDimension();
  const desktop = !(mobile || tablet);
  const app = useContext(Context);
  const [activeColor, setActiveColor] = useState(null);
  const [activeSize, setActiveSize] = useState(null);
  const modalReserveRef = createRef();
  const modalSizeRef = createRef();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const relatedProductItemWidth = getRelatedProductItemWidth(
    dimension,
    app.breakpoints,
  );
  const isMember = Boolean(app.currentUser);
  const isPromote = Boolean(product?.promotion);
  const [isFetchProduct, setIsFetchProduct] = useState(true);

  const [curIdx, setCurIdx] = useState(0);

  const fixedAddToCartButton = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const productSliderRef = useRef(null);
  const addItemToCartButtonRef = useRef(null);
  const [isShowSizeError, setIsShowSizeError] = useState(false);

  useEffect(() => {
    if (desktop || !isImageLoaded) {
      return;
    }

    const productSliderRect = productSliderRef.current.getBoundingClientRect();
    const addItemToCartButtonRect = addItemToCartButtonRef.current.getBoundingClientRect();
    const stickyAddToCartButton = () => {
      const breakpointStart = addItemToCartButtonRect.top;
      const bottomLine = window.scrollY + window.innerHeight;

      if (bottomLine < breakpointStart) {
        fixedAddToCartButton.current.style.width = '100%';
        fixedAddToCartButton.current.style.display = 'flex';
      } else {
        fixedAddToCartButton.current.style.display = 'none';
      }
    };

    stickyAddToCartButton();

    window.addEventListener('scroll', stickyAddToCartButton);

    return () => {
      window.removeEventListener('scroll', stickyAddToCartButton);
    };
  }, [desktop, isImageLoaded]);

  const infoSectionRef = useRef(null);
  const handleInfoSectionRef = useCallback(
    node => {
      if (!node || !desktop) {
        return;
      }

      const changeCircleIndexWhenScroll = () => {
        const containerTop = node.scrollTop;
        const imageTops = Array.from(
          node.querySelectorAll('.slider-images'),
        ).map(img => img.offsetTop);
        imageTops.forEach((top, index) => {
          if (
            containerTop >= imageTops[index] &&
            containerTop < imageTops[index + 1]
          ) {
            setCurIdx(index);
          }
        });
      };

      node.addEventListener('scroll', changeCircleIndexWhenScroll);

      infoSectionRef.current = node;

      return () => {
        node.removeEventListener('scroll', changeCircleIndexWhenScroll);
      };
    },
    [desktop],
  );

  useEffect(() => {
    async function fetchData() {
      try {
        if (!id) {
          return;
        }

        Actions.setLoading(true);
        setIsFetchProduct(true);
        const product = await Actions.fetchProductById(id);
        const groupVariants = aggregateProductVariants(product.variants);

        const _uiProductData = {
          ...product,
          groupVariants: {
            colors: groupVariants.colors,
            size_options: groupVariants.sizes,
            stocks: groupVariants.stocks,
            isValid: groupVariants.isValid,
          },
        };

        const defaultVariant = product.variants[0];

        if (defaultVariant) {
          setActiveColor(defaultVariant.color_img || defaultVariant.color);
          setActiveSize(defaultVariant.size.en.toLocaleUpperCase());
        }

        setProduct(_uiProductData);
      } catch (err) {
        setProduct(null);
      } finally {
        Actions.setLoading(false);
        setIsFetchProduct(false);
      }
    }

    fetchData();
  }, [location.search, id, isMember]);

  useEffect(() => {
    const fetch = async () => {
      if (isFetchProduct) {
        return;
      }
      const variantIds = product.variants.map(v => v.id);
      const resp = await Actions.queryStockByVariantIds(variantIds);
      setProduct(prev => ({
        ...prev,
        variants: resp,
        groupVariants: {
          ...prev.groupVariants,
          stocks: resp,
        },
      }));
    };
    fetch();
  }, [isFetchProduct, isMember]);

  const ratio = useMemo(() => {
    if (!product) {
      return 0;
    }
    return app.brandProductImageRatio[brandIdToBrandName[product.brand_id]];
  }, [app.brandProductImageRatio, product]);

  const targetVariant = useMemo(() => {
    if (!product) {
      return null;
    }

    if (!product.groupVariants.isValid) {
      return null;
    }

    return product.variants.find(
      v =>
        (v.color === activeColor || v.color_img === activeColor) &&
        v.size.en.toLocaleUpperCase() === activeSize,
    );
  }, [activeColor, activeSize, product]);

  const priceFlag = useMemo(() => {
    if (!targetVariant) {
      return 'none';
    }
    if (!isMember && !isPromote) {
      return 'none';
    } else if (isMember && isPromote) {
      return targetVariant.promote_price <= targetVariant.member_price
        ? 'promote'
        : 'member';
    } else if (isMember && !isPromote) {
      return 'member';
    } else {
      return 'promote';
    }
  }, [isMember, isPromote, targetVariant]);

  const priceTag = useMemo(() => {
    if (!targetVariant) {
      return null;
    }
    return getPriceTag({
      priceSet: {
        price: targetVariant.price,
        member_price: targetVariant.member_price,
        promote_price: targetVariant.promote_price,
      },
      isPromote,
      isMember,
      mobile,
      hasPrefix: true,
    });
  }, [isMember, isPromote, mobile, targetVariant]);

  const showAddToCartToast = () => {
    const isHasExtraPadding = !(
      fixedAddToCartButton.current.style.display === 'none'
    );
    Actions.setGlobalToastContent(
      <FlexColCenter
        style={{
          padding: isHasExtraPadding ? '20px 0 72px 0' : '20px 0 20px 0',
        }}>
        <div
          style={{
            fontSize: '16px',
            marginBottom: '12px',
            fontWeight: 'bold',
          }}>
          尺寸 {activeSize} 已加入購物車
        </div>
        <UnderlineButton
          onClick={() => {
            NavActions.navigate('/cart');
          }}
          style={{color: Color.mainWhite}}>
          前往付款
        </UnderlineButton>
      </FlexColCenter>,
    );
    setTimeout(() => {
      Actions.setGlobalToastContent(null);
    }, 2500);
  };

  const addItemToCart = async () => {
    try {
      setIsBtnLoading(true);

      if (isAnyAvailable && !isHasSelectedAvailableSize) {
        setIsShowSizeError(true);
        setIsBtnLoading(false);
        return;
      }

      const theVariant = app.cart.items.find(
        item => item.variant.id === targetVariant.id,
      );
      const nextQuantity = theVariant ? theVariant.quantity + 1 : 1;
      if (nextQuantity > targetVariant.stock) {
        Actions.setGlobalModalContent(
          <Alert
            icon={<img src={Box} alt="box" />}
            title="慢了一步"
            subTitle="商品已售完，無法加入購物車"
            cancelLabel="確定"
          />,
        );
        setIsBtnLoading(false);
        return;
      }

      const prevCartItems = app.cart?.items || [];
      const appendCartItems = [
        {variant: {...targetVariant, product}, quantity: 1},
        ...prevCartItems,
      ];
      const nextCartItems = getSummaryCartItems(appendCartItems);

      const isSuccess = await Actions.updateCart({
        items: getFormatCartItems(nextCartItems),
      });

      setIsBtnLoading(false);
      if (!isSuccess) {
        return;
      }

      if (desktop) {
        Actions.setShowMiniCart(true);
      } else {
        showAddToCartToast();
      }
    } catch (err) {
      setIsBtnLoading(false);
      Actions.setGlobalModalContent(
        <Alert
          icon={<img src={Box} alt="box" />}
          title="慢了一步"
          subTitle="商品已售完，無法加入購物車"
          cancelLabel="確定"
        />,
      );
      throw err;
    }
  };

  const memberDiscountRatio = useMemo(() => {
    if (!product || !app.currentUser) {
      return false;
    }
    return MEMBERSHIP_CONFIG[app.currentUser.vip_level].discount[
      brandIdToBrandName[product.brand_id]
    ];
  }, [app.currentUser, product]);

  const isAnyAvailable = useMemo(() => {
    if (!product) {
      return false;
    }
    return product.variants.some(v => v.stock > 0);
  }, [product]);

  const isHasSelectedAvailableSize = useMemo(() => {
    if (!product) {
      return null;
    }
    return product.groupVariants.size_options
      .map(s => {
        const available = isStockAvailable(product.groupVariants.stocks, {
          color: activeColor,
          size: s,
        });
        return activeSize === s && available;
      })
      .some(b => b);
  }, [activeColor, activeSize, product]);

  useEffect(() => {
    if (!product) {
      return;
    }
    const _queryParams = {...parsed};
    _queryParams.brand = brandIdToBrandName[product.brand_id];
    Actions.setActiveBrand(brandIdToBrandName[product.brand_id]);
    NavActions.navigate(`/product/?${queryString.stringify(_queryParams)}`, {
      replace: true,
    });
  }, [product]);

  if (!product) {
    return <Wrapper breakpoints={app.breakpoints} style={{height: '100vh'}} />;
  }

  const getCarouselAssets = _targetVariant => {
    if (!_targetVariant) {
      return [{image: ''}];
    }
    let nextResult;

    if (_targetVariant.video) {
      const _clonedNextResult = [
        ...targetVariant.images.map(image => ({
          image: image,
        })),
      ];
      _clonedNextResult.splice(1, 0, {video: _targetVariant.video});
      nextResult = _clonedNextResult;
    } else {
      nextResult = [
        ...targetVariant.images.map(image => ({
          image: image,
        })),
      ];
    }

    return nextResult;
  };

  return (
    <Wrapper breakpoints={app.breakpoints} data-nav-type="normal">
      {(mobile || tablet) && (
        <CustomNav
          left={
            <ChevronLeft
              onClick={async () => {
                await navigate(-1);
              }}
            />
          }
          right={<MiniCartIcon />}
        />
      )}
      <div className="breadcrumbs">
        <span
          style={{cursor: 'pointer'}}
          onClick={() => {
            NavActions.navigate('/home');
          }}>
          {brandsDisplayArr.find(
            brandObj => brandObj.key === brandIdToBrandName[product?.brand_id],
          ).display || null}
        </span>
        {/*<SvgIcon.ChevronRight size={16} color={Color.mainDark_70} />{' '}*/}
        {/*<span>{L.d(product.category?.title)}</span>*/}
        <SvgIcon.ChevronRight size={16} color={Color.mainDark_70} />{' '}
        <span
          style={{cursor: 'pointer'}}
          onClick={() => {
            NavActions.navigate(
              `/products?collection_id=${product.collection.id}`,
            );
          }}>
          {L.d(product.collection.title)}
        </span>
      </div>
      {desktop && (
        <div style={{position: 'absolute', top: 300, left: 50, zIndex: 8}}>
          {(targetVariant
            ? targetVariant.images.map(image => ({
                buttons: [],
                image,
              }))
            : []
          ).map((_, idx) => {
            const active = curIdx === idx;
            return (
              <div
                key={`${idx + (active ? '-active' : '')}`}
                onClick={() => {
                  setCurIdx(idx);
                  infoSectionRef.current.scrollTo({
                    top: infoSectionRef.current.querySelector(`#image-${idx}`)
                      .offsetTop,
                    behavior: 'smooth',
                  });
                }}>
                <AnimProgressCircle
                  size={32}
                  active={active}
                  stroke={Color.mainDark}
                />
              </div>
            );
          })}
        </div>
      )}

      <div ref={handleInfoSectionRef} className="info-section">
        <div className="product-slider">
          <ProductDetailSlider
            productSliderRef={productSliderRef}
            setIsImageLoaded={setIsImageLoaded}
            curIdx={curIdx}
            setCurIdx={setCurIdx}
            images={getCarouselAssets(targetVariant)}
            video={targetVariant?.video}
            ratio={ratio}
          />
        </div>
        <div
          className="product-info"
          data-nav-type={mobile || tablet ? 'solid' : 'solid'}>
          <div className="m-slide-hint" />
          {/* info */}
          <div>
            {product.series.map((s, index) => (
              <span
                key={index}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  NavActions.navigate(`/series?id=${s.id}`);
                }}>
                {L.d(s.title)}
                {product.series.length > 1 && <span>/</span>}
              </span>
            ))}
          </div>
          <div className="title">{L.d(product.title)}</div>

          <div className="price-area">{priceTag}</div>

          {priceFlag === 'member' && memberDiscountRatio !== 0 && (
            <div className="promotion-area">
              {L.d(MEMBERSHIP_LEVEL_DISPLAY[app.currentUser.vip_level])} -
              {memberDiscountRatio}%
            </div>
          )}
          {priceFlag === 'promote' && (
            <div className="promotion-area">
              {L.d(product.promotion.title)} -{100 - product.promotion.ratio} %
            </div>
          )}

          <div className="separate-line" style={{marginTop: 32}} />

          {/* colors */}
          {product.groupVariants.colors.length > 0 && (
            <Fragment>
              <div className="color-area">
                <div className="label">
                  {L.d(
                    product.groupVariants.colors.find(
                      c => c.color === activeColor,
                    )?.display,
                  )}
                </div>
                <div className="colors-wrapper">
                  {product.groupVariants.colors.map((c, idx) => {
                    return (
                      <ColorDot
                        key={idx}
                        texture={c.texture}
                        color={c.color}
                        active={
                          activeColor === c.color || activeColor === c.color_img
                        }
                        onClick={() => {
                          setActiveColor(c.color);
                          setActiveSize(
                            product.variants
                              .find(
                                v =>
                                  v.color === c.color ||
                                  v.color_img === c.color,
                              )
                              .size.en.toLocaleUpperCase(),
                          );
                        }}
                        style={{marginRight: 16}}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="separate-line" />
            </Fragment>
          )}

          {/* size */}
          {product.groupVariants.size_options.length > 0 && (
            <div className="size-area">
              <FlexRowCenter style={{justifyContent: 'flex-start'}}>
                {L.d(product.size_img) ? (
                  <UnderlineButton
                    className="label"
                    onClick={() => {
                      modalSizeRef.current.open();
                    }}>
                    尺寸表
                  </UnderlineButton>
                ) : (
                  <div className="label">尺寸表</div>
                )}
                {isShowSizeError && (
                  <div className="label" style={{color: Color.red}}>
                    請選擇尺寸
                  </div>
                )}
              </FlexRowCenter>
              <div className="sizes-wrapper">
                {product.groupVariants.size_options.map((s, index) => {
                  const available = isStockAvailable(
                    product.groupVariants.stocks,
                    {
                      color: activeColor,
                      size: s,
                    },
                  );
                  const active =
                    activeSize === s.toLocaleUpperCase() && available;
                  return (
                    <SizeBtn
                      key={index}
                      breakpoints={app.breakpoints}
                      active={active}
                      disabled={!available}
                      onClick={() => {
                        if (available) {
                          setActiveSize(s.toLocaleUpperCase());
                          setIsShowSizeError(false);
                        }
                      }}>
                      {s}
                    </SizeBtn>
                  );
                })}
              </div>
            </div>
          )}

          {(mobile || tablet) && (
            <RectButton
              refer={fixedAddToCartButton}
              skin={BUTTON_SKIN.PRIMARY}
              text={isHasSelectedAvailableSize ? '加入購物車' : '請選擇尺寸'}
              isDisabled={!isAnyAvailable}
              isLoading={isBtnLoading}
              size={mobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
              style={{
                position: 'fixed',
                bottom: 0,
                left: '50%',
                width: '100%',
                zIndex: 10,
                transform: 'translateX(-50%)',
              }}
              onClick={addItemToCart}
            />
          )}
          {/* action buttons */}
          <div className="cta-area">
            <RectButton
              skin={BUTTON_SKIN.PRIMARY}
              text={isHasSelectedAvailableSize ? '加入購物車' : '請選擇尺寸'}
              isDisabled={!isAnyAvailable}
              isLoading={isBtnLoading}
              size={mobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}
              style={
                mobile || tablet
                  ? {marginBottom: '12px', width: '100%'}
                  : {marginRight: '12px', width: '100%'}
              }
              onClick={addItemToCart}
              refer={addItemToCartButtonRef}
            />
            {/*FIXME: @guychienll future feature comment first*/}
            {/*<RectButton*/}
            {/*  skin={BUTTON_SKIN.DEFAULT}*/}
            {/*  size={mobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.LARGE}*/}
            {/*  style={{width: '100%'}}*/}
            {/*  onClick={() =>*/}
            {/*    modalReserveRef.current.open({*/}
            {/*      product,*/}
            {/*      activeColor,*/}
            {/*      setActiveColor,*/}
            {/*      activeSize,*/}
            {/*      setActiveSize,*/}
            {/*      modalSizeRef,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  text={'預約保留商品'}*/}
            {/*  isDisabled={!(targetVariant && targetVariant.stock > 0)}*/}
            {/*/>*/}
          </div>

          {/* extra info */}
          <ExtraInfoSections
            location={location}
            product={product}
            targetVariant={targetVariant}
          />
        </div>
      </div>

      {product.style_with_products.length > 0 && (
        <div className="related-products" id={STYLE_WITH_DOM_ID}>
          <div className="header">STYLE IT WITH</div>
          <div className="container">
            {product.style_with_products.map((p, idx) => {
              return (
                <div className="item-wrapper" key={idx}>
                  <ProductItem
                    itemWidth={relatedProductItemWidth}
                    product={{...p, name: p.title}}
                  />
                </div>
              );
            })}
            {/* make up items */
            [1, 2, 3].map((i, index) => (
              <div
                key={index}
                style={{width: relatedProductItemWidth, height: 1}}
              />
            ))}
          </div>
        </div>
      )}

      <div className="related-products">
        <div className="header">RECOMMEND</div>
        <div className="container">
          {product.recommend_products.map((p, idx) => {
            return (
              <div className="item-wrapper" key={idx}>
                <ProductItem
                  itemWidth={relatedProductItemWidth}
                  product={{...p, name: p.title}}
                />
              </div>
            );
          })}

          {/* make up items */
          [1, 2, 3].map((i, index) => (
            <div
              key={index}
              style={{width: relatedProductItemWidth, height: 1}}
            />
          ))}
        </div>
      </div>

      <ProductReserveModal
        getInstance={inst => {
          modalReserveRef.current = inst;
        }}
      />
      <ProductDetailSizeModal
        uri={L.d(product.size_img)}
        getInstance={inst => {
          modalSizeRef.current = inst;
        }}
      />
    </Wrapper>
  );
}

const SizeBtn = styled.div`
  min-width: 48px;
  height: 32px;
  border: 1px solid ${Color.mainDark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding:  0 5px;
  ${props =>
    props.disabled ? `text-decoration: line-through; color: #b8b8b8;` : ''};
  border-width: 1px;
  border-style: solid;
  ${fstyles.text14}
  ${props =>
    props.active
      ? `border-color: ${Color.mainDark};`
      : 'border-color: transparent;'}

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
  min-width: 48px;
}

`;

const Wrapper = styled.div`
  color: ${Color.mainDark};
  position: relative;

  & > .breadcrumbs {
    color: ${Color.mainDark_70};
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-left: 50px;
    padding-bottom: 20px;
    ${fstyles.text14}
  }

  & > .related-products {
    padding-top: 60px;
    & > .header {
      text-align: center;
      font-weight: bold;
      padding-bottom: 40px;
      ${fstyles.text24}
    }

    & > .container {
      & > .item-wrapper {
      }
      display: flex;
      flex-wrap: wrap;
      padding: 0 50px;

      justify-content: space-between;
    }
  }

  & > .info-section {
    display: flex;
    position: relative;
    max-height: calc(100vh - 164px);
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    & > .product-slider {
      width: 50%;
    }

    & > .product-info {
      padding: 0 50px;
      width: 50%;
      position: sticky;
      top: 0;
      overflow-y: auto;

      & .m-slide-hint {
        display: none;
      }
      & > .series {
        ${fstyles.text12}
      }
      & > .title {
        margin-bottom: 20px;
        ${fstyles.text20}
      }
      & > .price-area {
        display: flex;
        align-items: baseline;
        margin-bottom: 4px;
        ${fstyles.text14}
        & > .price {
          font-weight: bold;
          ${fstyles.text20}
        }
        & > .deprecated {
          color: ${Color.mainDark_30};
          margin-right: 8px;
          text-decoration: line-through;
          ${fstyles.text14}
        }
      }

      & > .promotion-area {
        color: #cca75c;
        max-width: 186px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & > .color-area {
        padding: 24px 0;
        & > .label {
          margin-bottom: 16px;
          ${fstyles.text14}
        }
        & > .colors-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      & > .size-area {
        padding-top: 24px;
        padding-bottom: 20px;
        & .label {
          margin-bottom: 12px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          ${fstyles.text14}

          & > .error {
            color: #e22828;
            margin-left: 22px;
            ${fstyles.text14}
          }
        }
        & > .sizes-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      & > .cta-area {
        padding-top: 20px;
        padding-bottom: 32px;
        display: flex;
        justify-content: space-between;
      }

      & .separate-line {
        height: 1px;
        width: 100%;
        background-color: ${Color.mainDark_10};
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    position: unset;
    & > .breadcrumbs {
      display: none;
    }
    & > .related-products {
      & > .container {
        padding: 0 0 0 35px;
        flex-wrap: nowrap;
        overflow: auto;
        justify-content: flex-start;
        & > .item-wrapper {
          margin-right: 16px;
        }
      }
    }
    & > .info-section {
      flex-direction: column;
      max-height: unset;

      & > .product-slider {
        width: 100%;
      }
      & > .product-info {
        width: 100%;
        padding: 0 35px;

        & .m-slide-hint {
          display: block;
          padding: 12px 0 16px;
          &:before {
            content: '';
            display: block;
            margin: 0 auto;
            background-color: ${Color.mainDark_20};
            width: 52px;
            height: 4px;
            border-radius: 2.5px;
          }
        }
        & > .series {
        }
        & > .title {
          ${fstyles.text16}
        }
        & > .price-area {
          & > .price {
            ${fstyles.text16}
          }
          & > .deprecated {
            ${fstyles.text12}
          }
        }
        & > .promotion-area {
          & > .item {
          }
        }

        & > .color-area {
          & > .label {
          }
          & > .colors-wrapper {
          }
        }

        & > .size-area {
          & > .label {
          }
          & > .sizes-wrapper {
          }
        }

        & > .cta-area {
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & > .breadcrumbs {
    }
    & > .related-products {
      & > .header {
        padding-bottom: 24px;
        ${fstyles.text20}
      }

      & > .container {
        padding: 0 0 0 20px;
      }
    }
    & > .info-section {
      flex-direction: column;

      & > .product-slider {
        width: 100%;
      }
      & > .product-info {
        padding: 0 20px;
        & .m-slide-hint {
          padding: 8px 0 12px;
        }

        & > .series {
        }
        & > .title {
        }
        & > .price-area {
          & > .price {
          }
          & > .deprecated {
          }
        }
        & > .promotion-area {
          & > .item {
          }
        }

        & > .color-area {
          & > .label {
          }
          & > .colors-wrapper {
          }
        }

        & > .size-area {
          & > .label {
          }
          & > .sizes-wrapper {
          }
        }

        & > .cta-area {
        }
      }
    }
  }
`;

function ExtraInfoSections(props) {
  const {product, targetVariant, location} = props;
  const [activeKey, setActiveKey] = useState(null);
  const {mobile, tablet} = useDimension();

  const items = L.d(targetVariant?.material)
    ? [
        {key: 'detail', content: L.d(product.info_detail), display: '詳細說明'},
        {
          key: 'material',
          content: L.d(targetVariant.material),
          display: '材質',
        },
        {
          key: 'shipping',
          content:
            '送貨上門：\n' +
            '下單完成後，將在二至五日送達指定地點 - 免運費。偏遠地區將會延長一至二個工作天。\n' +
            '偏遠地區為: 新北市(萬里區、金山區、石碇區、石門區、三芝區)，新竹縣(關西鎮、寶山鄉、橫山鄉、峨眉鄉)、彰化市(二林鄉、溪州鄉、大城鄉、竹塘鄉)、台中市(新社區)、南投縣(水里鄉、魚池鄉、鹿谷鄉)、雲林縣(台西鄉、麥寮鄉、水林鄉、四湖鄉、口湖鄉)、台南市(七股區、將軍區、白河區、東山區、北門區、玉井區、楠西區、大內區、布袋鎮)、屏東市(麟洛鄉、崁頂鄉、新埤鄉、南州鄉、東港鎮、新園鄉、枋寮鄉、恆春鎮、里港鄉、高樹鄉、竹田鄉)\n' +
            '外島無法配送。\n\n' +
            '超商取貨:\n' +
            '您可在 7-11 / 全家/ 萊爾富 的交貨點選擇取貨。\n' +
            '收到確認通知後，將在四至十 日送達您指定的超商地點 - 免運費。偏遠地區將會延長一至二個工作天。\n' +
            '偏遠地區為: 新北市(萬里區、金山區、石碇區、石門區、三芝區)，新竹縣(關西鎮、寶山鄉、橫山鄉、峨眉鄉)、彰化市(二林鄉、溪州鄉、大城鄉、竹塘鄉)、台中市(新社區)、南投縣(水里鄉、魚池鄉、鹿谷鄉)、雲林縣(台西鄉、麥寮鄉、水林鄉、四湖鄉、口湖鄉)、台南市(七股區、將軍區、白河區、東山區、北門區、玉井區、楠西區、大內區、布袋鎮)、屏東市(麟洛鄉、崁頂鄉、新埤鄉、南州鄉、東港鎮、新園鄉、枋寮鄉、恆春鎮、里港鄉、高樹鄉、竹田鄉)\n' +
            '外島無法配送。\n\n' +
            '退貨:\n' +
            '為提供您便捷的網上購物體驗，您在tungrp.com購買的商品，自收貨之日起7天內可免費退貨。每筆訂單，限退貨乙次。\n' +
            '有關退貨，商品必須是全新未使用、並有掛著完整TUN標籤與品牌吊牌。若有附保證書等任何說明書也請完整退還。',
          display: '配送 / 退換貨服務',
        },
      ]
    : [
        {key: 'detail', content: L.d(product.info_detail), display: '詳細說明'},
        {
          key: 'shipping',
          content:
            '送貨上門：\n' +
            '下單完成後，將在二至五日送達指定地點 - 免運費。偏遠地區將會延長一至二個工作天。\n' +
            '偏遠地區為: 新北市(萬里區、金山區、石碇區、石門區、三芝區)，新竹縣(關西鎮、寶山鄉、橫山鄉、峨眉鄉)、彰化市(二林鄉、溪州鄉、大城鄉、竹塘鄉)、台中市(新社區)、南投縣(水里鄉、魚池鄉、鹿谷鄉)、雲林縣(台西鄉、麥寮鄉、水林鄉、四湖鄉、口湖鄉)、台南市(七股區、將軍區、白河區、東山區、北門區、玉井區、楠西區、大內區、布袋鎮)、屏東市(麟洛鄉、崁頂鄉、新埤鄉、南州鄉、東港鎮、新園鄉、枋寮鄉、恆春鎮、里港鄉、高樹鄉、竹田鄉)\n' +
            '外島無法配送。\n\n' +
            '超商取貨:\n' +
            '您可在 7-11 / 全家/ 萊爾富 的交貨點選擇取貨。\n' +
            '收到確認通知後，將在四至十 日送達您指定的超商地點 - 免運費。偏遠地區將會延長一至二個工作天。\n' +
            '偏遠地區為: 新北市(萬里區、金山區、石碇區、石門區、三芝區)，新竹縣(關西鎮、寶山鄉、橫山鄉、峨眉鄉)、彰化市(二林鄉、溪州鄉、大城鄉、竹塘鄉)、台中市(新社區)、南投縣(水里鄉、魚池鄉、鹿谷鄉)、雲林縣(台西鄉、麥寮鄉、水林鄉、四湖鄉、口湖鄉)、台南市(七股區、將軍區、白河區、東山區、北門區、玉井區、楠西區、大內區、布袋鎮)、屏東市(麟洛鄉、崁頂鄉、新埤鄉、南州鄉、東港鎮、新園鄉、枋寮鄉、恆春鎮、里港鄉、高樹鄉、竹田鄉)\n' +
            '外島無法配送。\n\n' +
            '退貨:\n' +
            '為提供您便捷的網上購物體驗，您在tungrp.com購買的商品，自收貨之日起7天內可免費退貨。每筆訂單，限退貨乙次。\n' +
            '有關退貨，商品必須是全新未使用、並有掛著完整TUN標籤與品牌吊牌。若有附保證書等任何說明書也請完整退還。',
          display: '配送 / 退換貨服務',
        },
      ];

  return (
    <ExtraInfoSectionsWrapper>
      {items.map((item, idx) => {
        const isActive = item.key === activeKey;
        return (
          <div className="section" key={idx}>
            <div
              className="header"
              onClick={() =>
                isActive ? setActiveKey(null) : setActiveKey(item.key)
              }>
              <div className="title">{item.display}</div>
              {isActive ? (
                <SvgIcon.Minus size={24} color={Color.mainDark_70} />
              ) : (
                <SvgIcon.Plus size={24} color={Color.mainDark_70} />
              )}
            </div>
            <div className={`content${isActive ? ' active' : ''}`}>
              <div>{item.content}</div>
            </div>
          </div>
        );
      })}

      <div className="section">
        <div className="header">
          <div className="title">{'分享'}</div>
          <div
            className="share-wrapper"
            style={{display: 'flex', alignItems: 'center'}}>
            {!(mobile || tablet) ? (
              <CopyToClipboard text={window.location.href} showTooltip={false}>
                <div
                  className="link"
                  style={{
                    textDecoration: 'underline',
                    fontSize: 14,
                    lineHeight: '22px',
                    marginRight: 16,
                  }}
                  onClick={() => {
                    document.execCommand('copy', true, window.location.herf);
                    Actions.setGlobalToastContent(
                      <label style={{color: '#fff', padding: '20px 0'}}>
                        複製成功
                      </label>,
                    );
                    setTimeout(() => {
                      Actions.setGlobalToastContent(null);
                    }, 2000);
                  }}>
                  複製網址
                </div>
              </CopyToClipboard>
            ) : (
              <div
                onClick={() => {
                  if (navigator.share) {
                    const shareData = {
                      title: 'tungrp',
                      url: window.location.href,
                    };
                    navigator
                      .share(shareData)
                      .then(() => console.log('成功！'))
                      .catch(error => console.log('發生錯誤', error));
                  }
                }}>
                <SvgIcon.Share />
              </div>
            )}
            {!(mobile || tablet) && (
              <div
                style={{margin: 0, padding: 0, width: 25, height: 25}}
                onClick={() => {
                  window.open(
                    `https://www.facebook.com/sharer.php?display=page&u=${window.location.href}`,
                  );
                }}>
                <SvgIcon.Facebook size={25} color={Color.mainDark} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="section">
        <div
          className="header"
          onClick={() => ScrollTo(STYLE_WITH_DOM_ID, -30)}>
          <div className="title">其他推薦商品</div>
        </div>
      </div>
    </ExtraInfoSectionsWrapper>
  );
}

const ExtraInfoSectionsWrapper = styled.div`
  & > .section {
    padding: 13px;
    border-bottom: 1px solid ${Color.mainDark_10};

    & > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      & > .share-wrapper {
        & .fb:hover,
        & .link:hover {
          opacity: 0.7;
        }
      }

      & > .title {
        ${fstyles.text14}
      }
    }

    & > .content.active {
      & > div {
        display: block;
      }
    }

    & > .content {
      overflow: hidden;

      & > div {
        display: none;
        white-space: pre-wrap;
        padding-top: 9px;
      }

      color: ${Color.mainDark_70};
      ${fstyles.text14}
    }
  }
`;

export default ProductPage;
