import {Actions, Breakpoints} from '../../Contexts/AppContext';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from '../RectButton';
import React from 'react';
import {useDimension} from '../../Hooks/AppHooks';
import styled from 'styled-components';
import {Color} from '../Widget';

const Alert = props => {
  const {
    icon = null,
    title = null,
    subTitle = null,
    cancelLabel,
    onClick = () => {
      Actions.setGlobalModalContent(null);
    },
    children,
  } = props;
  const {dimension} = useDimension();
  const mobile = dimension.innerWidth <= Breakpoints.tablet;

  return (
    <Wrapper mobile={mobile}>
      {icon}
      <div className="content">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
      {children}
      <RectButton
        size={BUTTON_SIZE.LARGE}
        style={{width: '100%', marginTop: '40px'}}
        skin={BUTTON_SKIN.PRIMARY}
        text={cancelLabel}
        onClick={onClick}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
  background-color: #fff;
  box-sizing: border-box;
  width: ${props => (props.mobile ? '95%' : '430px')};
  & > .content {
    text-align: center;
    padding: 0 20px;
    & > .title {
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 20px;
      color: ${Color.mainDark};
    }
    & > .sub-title {
      font-weight: 300;
      font-size: 16px;
      color: ${Color.mainDark_70};
    }
  }
`;

export default Alert;
