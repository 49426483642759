function ScrollTo(eleId, offset = 0) {
  if (typeof window !== 'undefined') {
    const ele = document.getElementById(eleId);
    window.scrollTo({
      top: ele.getBoundingClientRect().top + window.pageYOffset + offset,
      behavior: 'smooth',
    });
  }
}

export default ScrollTo;
