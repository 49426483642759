import React, {useCallback, useMemo, useRef, useState} from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Color, FontSize} from './Widget';
import ActionCreator from '../ActionCreator';
import FixedRatioImage from './FixedRatioImage';
import AnimProgressCircle from './AnimProgressCircle';
import {
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from 'react-image-magnifiers';
import {useDimension} from '../Hooks/AppHooks';

function ProductDetailSlider(props) {
  let {
    images = [],
    ratio = 5 / 4,
    onSliderIdxChanged = () => 0,
    breakpoints = {tablet: 1280, mobile: 640},
    setCurIdx,
    curIdx,
    productSliderRef,
    setIsImageLoaded,
  } = props;
  const {mobile, tablet, dimension} = useDimension();
  const desktop = !mobile && !tablet;

  const previewImageRef = useRef(null);
  const [previewImageRect, setPreviewImageRect] = useState(null);

  const handlePreviewImageRef = useCallback(node => {
    previewImageRef.current = node;
    setPreviewImageRect(node?.getBoundingClientRect());
  }, []);

  const zoomImageWidth = useMemo(() => {
    if (!previewImageRect) {
      return 0;
    }
    const {width} = previewImageRect;

    return width;
  }, [previewImageRect]);
  return (
    <Wrapper breakpoints={breakpoints}>
      {desktop && (
        <div ref={handlePreviewImageRef}>
          <MagnifierContainer switchSides={true}>
            <div style={{width: '100%'}}>
              {images.map((item, idx) => {
                return (
                  <div
                    id={`image-${idx}`}
                    className="slider-images"
                    style={{width: '100%'}}
                    key={idx}
                    onMouseEnter={() => {
                      setCurIdx(idx);
                    }}>
                    {/*{item.video && (*/}
                    {/*  <video*/}
                    {/*    loop={true}*/}
                    {/*    autoPlay={true}*/}
                    {/*    style={{objectFit: 'cover'}}*/}
                    {/*    controls={false}*/}
                    {/*    width={*/}
                    {/*      previewImageRef.current?.getBoundingClientRect()*/}
                    {/*        ?.width || 0*/}
                    {/*    }*/}
                    {/*    height={*/}
                    {/*      previewImageRef.current?.getBoundingClientRect()*/}
                    {/*        ?.width || 0*/}
                    {/*    }>*/}
                    {/*    <source src={item.video} type="video/mp4" />*/}
                    {/*  </video>*/}
                    {/*)}*/}
                    {!item.video && <MagnifierPreview imageSrc={item.image} />}
                  </div>
                );
              })}
            </div>
            <MagnifierZoom
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: 8,
                width: zoomImageWidth,
                height: '100vh',
              }}
              imageSrc={images[curIdx]?.image || images[0]?.image}
            />
          </MagnifierContainer>
        </div>
      )}

      {!desktop && (
        <div ref={productSliderRef}>
          <Carousel
            wrapAround
            slideIndex={curIdx}
            afterSlide={idx => onSliderIdxChanged(idx)}
            autoplay={false}
            heightMode="max"
            getControlsContainerStyles={key => {
              switch (key) {
                case 'CenterLeft':
                  return {
                    top: 0,
                    bottom: 0,
                  };
                case 'CenterBottom':
                  return {
                    width: '100%',
                  };
                default:
                  // will apply all other keys
                  return {};
              }
            }}
            renderCenterRightControls={({nextSlide}) => null}
            renderBottomCenterControls={({currentSlide, goToSlide}) => (
              <div className={'control-bottom-wrapper'}>
                {images.map((_, idx) => {
                  const active = currentSlide === idx;
                  return (
                    <div
                      key={`${idx + (active ? '-active' : '')}`}
                      onClick={() => goToSlide(idx)}>
                      <AnimProgressCircle
                        size={32}
                        active={active}
                        stroke={Color.mainDark}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            renderCenterLeftControls={({currentSlide, goToSlide}) => (
              <div className={'control-left-wrapper'}>
                {images.map((_, idx) => {
                  const active = currentSlide === idx;
                  return (
                    <div
                      key={`${idx + (active ? '-active' : '')}`}
                      onClick={() => goToSlide(idx)}>
                      <AnimProgressCircle
                        size={32}
                        active={active}
                        stroke={Color.mainDark}
                      />
                    </div>
                  );
                })}
              </div>
            )}>
            {images.map((item, idx) => (
              <SliderItem
                item={item}
                key={idx}
                ratio={ratio}
                breakpoints={breakpoints}
                setIsImageLoaded={setIsImageLoaded}
              />
            ))}
          </Carousel>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .slider-control-centerright,
  & .slider-control-centerleft {
    /* overwrite nuka-carousel style */
    transform: none !important;

    justify-content: center;
    display: flex;
    align-items: center;
  }

  & .control-left-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
  }
  & .control-bottom-wrapper {
    display: none;
    padding-left: 35px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & .control-left-wrapper {
      display: none;
    }

    & .control-bottom-wrapper {
      display: flex;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
  }
`;

function _SliderItem(props) {
  let {
    item,
    ratio = 5 / 4,
    breakpoints = {tablet: 1280, mobile: 640},
    setIsImageLoaded,
  } = props;

  return (
    <SliderItemWrapper breakpoints={breakpoints}>
      {item.video ? (
        <video
          loop={true}
          style={{objectFit: 'cover'}}
          autoPlay={true}
          controls={false}
          width="100%"
          height="100%">
          <source src={item.video} type="video/mp4" />
        </video>
      ) : (
        <div className="image">
          <FixedRatioImage
            ratio={ratio}
            className="image"
            image={item.image}
            mode="cover"
          />
          <img
            src={item.image}
            alt="img load"
            style={{visibility: 'hidden', display: 'none'}}
            onLoad={() => {
              setIsImageLoaded(true);
            }}
          />
        </div>
      )}
    </SliderItemWrapper>
  );
}

let SliderItem = connect(null, ActionCreator)(_SliderItem);

const SliderItemWrapper = styled.div`
  height: 100%;
  position: relative;

  & > .images-row {
    display: flex;
    height: 100%;
    & > .image {
      flex: 1;
      background-size: cover;
      background-position: center;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    & > .images-row {
      & > .image:first-child,
      & > .image:last-child {
        display: none;
      }
    }
  }

  background: url(${props => props.image || ''});
  background-size: cover;
  background-position: center;

  & > .container {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    & .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 32px;
      margin-top: 20px;

      & > .button {
        min-width: 160px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${Color.mainDark};
        color: white;
        font-size: ${FontSize.paragraph}px;
      }
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.mobile}px) {
    & .buttons {
      flex-direction: column;
      & > .button {
        margin-bottom: 12px;
        margin-right: 0px;
      }

      & > .button::last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export default ProductDetailSlider;
