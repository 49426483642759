import React from 'react';

function ProgressCircle(props) {
  const [percentage, setPercentage] = React.useState(0);
  const {
    active,
    animDuration = 250,
    size = 32,
    strokeWidth = 1,
    stroke = 'white',
    fill = 'transparent',
    style = {},
  } = props;

  React.useEffect(() => {
    const initialDelay = 500,
      tick = 25;
    let curr = 0;
    let step = 100 / (animDuration / tick);
    let timer = null;
    setTimeout(() => {
      timer = setInterval(() => {
        curr += step;
        setPercentage(curr);
        if (curr >= 100 && timer !== null) {
          clearInterval(timer);
          timer = null;
        }
      }, tick);
    }, initialDelay);

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [animDuration]);

  const wrapperStyles = {
    width: size,
    height: size,
    backgroundColor: 'transparent',
  };
  const svgAttrs = {width: size, height: size};
  const radius = (size - strokeWidth) / 2;
  const totalLength = 2 * Math.PI * radius;
  const circleAttrs = {
    r: radius,
    cx: size / 2,
    cy: size / 2,
    strokeWidth,
    strokeDasharray: [
      0,
      totalLength * (1 - percentage / 100),
      totalLength * (percentage / 100),
    ],
    stroke,
    fill,
  };
  const innerCircleAttrs = {
    r: 4,
    cx: size / 2,
    cy: size / 2,
    strokeWidth: 0,
    fill: stroke,
  };

  return (
    <div style={{...wrapperStyles, ...style}}>
      <svg {...svgAttrs}>
        {active && <circle {...circleAttrs} />}
        <circle {...innerCircleAttrs} />
      </svg>
    </div>
  );
}

export default ProgressCircle;
