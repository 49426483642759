import React, {useEffect, useRef, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import MapStyleArray from './Map.style.json';
import {StaticImage} from './StaticImage';
import styled from 'styled-components';
import {Color} from './Widget';
import {useDimension} from '../Hooks/AppHooks';

const GOOGLE_API_KEY = 'AIzaSyA1p97EMG4hATeN7VjHsQCCQDKJgkqy9Ow';

function _calculateCenter(data) {
  let sumLat = 0,
    sumLng = 0;
  for (let datum of data) {
    sumLat += datum.lat;
    sumLng += datum.lng;
  }

  return {
    lat: Math.round((Math.pow(10, 6) * sumLat) / data.length) / Math.pow(10, 6),
    lng: Math.round((Math.pow(10, 6) * sumLng) / data.length) / Math.pow(10, 6),
  };
}

const Taiwan = {
  Taipei: {
    lat: 25.042705,
    lng: 121.567298,
  },
  TaiChung: {
    lat: 24.147736,
    lng: 120.673645,
  },
  Center: {
    lat: 23.583234,
    lng: 120.5825975,
  },
};

function StoreMap(props) {
  const {
    stores,
    activeStoreId,
    setActiveStoreId = () => {},
    hideFullScreenController = false,
    isInApp = false,
    showMarkerIdx = true,
    curLocation,
  } = props;
  const {mobile, tablet} = useDimension();
  const [center, setCenter] = useState(Taiwan.Center);
  const locationBtnRef = useRef(null);

  const activeStore = stores.find(
    s => parseInt(s.id) === parseInt(activeStoreId),
  );

  useEffect(() => {
    if (activeStore) {
      setCenter({
        lng: activeStore.lng,
        lat: activeStore.lat,
      });
    } else {
      setCenter(Taiwan.Center);
    }
  }, [activeStore]);

  useEffect(() => {
    let listener = null;
    if (curLocation && locationBtnRef.current) {
      listener = () => {
        setCenter(curLocation);
      };
      locationBtnRef.current.addEventListener('click', listener);
    }
  }, [curLocation, locationBtnRef.current]);

  const zoom = activeStore || curLocation ? 14 : 7;

  return (
    <Wrapper
      hideFullScreenController={hideFullScreenController}
      style={{height: props.height || '100%', width: '100%'}}>
      <GoogleMapReact
        options={{
          styles: MapStyleArray,
          gestureHandling: isInApp ? 'greedy' : 'auto',
          fullscreenControl: false,
        }}
        bootstrapURLKeys={{key: GOOGLE_API_KEY}}
        center={center}
        zoom={zoom}
        onDragEnd={map => {
          setCenter({
            lat: map.center.lat(),
            lng: map.center.lng(),
          });
        }}
        onGoogleApiLoaded={({map, maps}) => {
          const locationButton = document.createElement('div');
          locationButton.innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">\n    <g fill="none" fill-rule="evenodd">\n        <g fill="#141414" fill-rule="nonzero">\n            <g>\n                <g>\n                    <g>\n                        <g>\n                            <path d="M10.792 0v2.363c3.817.33 6.845 3.419 7.082 7.262h2.348v1.167H17.86c-.32 3.687-3.212 6.638-6.872 7.048v2.382H9.818v-2.338c-3.933-.146-7.12-3.21-7.456-7.092H0V9.625h2.348C2.59 5.717 5.717 2.59 9.625 2.348V0h1.167zm-.68 3.5C6.46 3.5 3.5 6.46 3.5 10.111s2.96 6.611 6.611 6.611 6.611-2.96 6.611-6.61c0-3.652-2.96-6.612-6.61-6.612zm0 2.722c2.147 0 3.888 1.741 3.888 3.89C14 12.258 12.259 14 10.111 14c-2.148 0-3.889-1.741-3.889-3.889 0-2.148 1.741-3.889 3.89-3.889z" transform="translate(-697 -140) translate(0 120) translate(689 12) translate(8 8) translate(3.889 3.889)"/>\n                        </g>\n                    </g>\n                </g>\n            </g>\n        </g>\n    </g>\n</svg>\n';
          locationButton.classList.add('current-position-button');
          locationButton.style.marginTop = mobile || tablet ? '10px' : '0';

          const position =
            mobile || tablet
              ? maps.ControlPosition.TOP_RIGHT
              : maps.ControlPosition.RIGHT_BOTTOM;
          map.controls[position].push(locationButton);
          locationBtnRef.current = locationButton;
        }}>
        {stores.map((d, idx) => (
          <Marker
            showMarkerIdx={showMarkerIdx}
            key={idx}
            idx={idx}
            lat={d.lat}
            lng={d.lng}
            store={d}
            active={d.id.toString() === activeStoreId}
            onClick={() => {
              setActiveStoreId(d.id);
              const targetStore = stores.find(s => s.id === d.id);
              const position = {
                lng: targetStore.lng,
                lat: targetStore.lat,
              };
              setCenter(position);
            }}
          />
        ))}
      </GoogleMapReact>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .gm-control-active.gm-fullscreen-control {
    ${props => (props.hideFullScreenController ? 'display: none;' : '')}
  }
  & .current-position-button {
    width: 40px;
    height: 40px;
    background-color: ${Color.mainWhite};
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    & svg,
    path {
      color: rgba(20, 20, 20, 0.7);
    }
    &:hover {
      & svg,
      path {
        color: rgba(20, 20, 20, 1);
      }
    }
  }
`;

function Marker(props) {
  let {active, idx, store, onClick, showMarkerIdx} = props;

  if (active) {
    return (
      <div
        onClick={evt => {
          onClick(store, idx);
          evt.stopPropagation();
        }}
        style={{
          width: 32,
          height: 40,
          color: 'white',
          transform: 'translate(-16px, -40px)',
          position: 'relative',
        }}>
        <div
          style={{
            position: 'absolute',
            color: 'white',
            top: 8,
            left: 16,
            zIndex: 1,
            transform: 'translate(-50%, 0%)',
          }}>
          {showMarkerIdx ? `${idx + 1}` : ''}
        </div>
        <StaticImage path="" name="icon-map-selected.png" />
      </div>
    );
  }

  return (
    <div
      onClick={evt => {
        onClick(store, idx);
        evt.stopPropagation();
      }}
      style={{
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: active ? 'red' : 'black',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {showMarkerIdx ? `${idx + 1}` : ''}
    </div>
  );
}

export default StoreMap;
