import React from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import * as AppContent from '../Contexts/AppContext';
import * as SvgIcon from './SvgIcon';
import {Color, fstyles} from './Widget';

function ModalContent(props) {
  const {close, uri} = props;
  const breakpoints = AppContent.Actions.getBreakpoints();

  return (
    <OutsideWrapper>
      <ModalContentWrapper breakpoints={breakpoints}>
        <div className="header">
          <div className="title">尺寸表</div>
          <div className="close" onClick={close}>
            <SvgIcon.Cross size={24} color={Color.mainDark} />
          </div>
        </div>
        <div className="content">
          <img src={uri} alt="size" />
        </div>
      </ModalContentWrapper>
    </OutsideWrapper>
  );
}

function Modal(props) {
  const {getInstance, uri} = props;

  return (
    <SlideInPanel
      position="bottom-to-center"
      dismissOnClickBackdrop={false}
      size={300}
      style={{backgroundColor: 'transparent'}}
      getInstance={inst => {
        getInstance({
          open: async () => {
            inst.open(<ModalContent uri={uri} close={inst.close.bind(inst)} />);
          },
          close: () => {
            inst.close();
          },
        });
      }}
    />
  );
}

const OutsideWrapper = styled.div``;

const ModalContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 888px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;

  & > .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 64px;
    width: 100%;
    background-color: #fff;

    & > .title {
      ${fstyles.text20}
    }

    & > .close {
      position: absolute;
      right: 0px;
      padding-right: 20px;
      cursor: pointer;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0;
    height: 100%;
    overflow: auto;
    & > img {
      width: 90%;
    }
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
  }
`;

export default Modal;
