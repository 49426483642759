import React, {useRef} from 'react';
import styled from 'styled-components';
import SlideInPanel from './SlideInPanel';
import {Color, fstyles} from './Widget';
import {useDimension} from '../Hooks/AppHooks';

function PickerContent(props) {
  const {title, bottom, options} = props;
  return (
    <ContentWrapper>
      <div className="title">{title}</div>
      <div className="options">{options}</div>
      <div className="bottom">{bottom}</div>
    </ContentWrapper>
  );
}

function BottomSlidePicker(props) {
  const panelRef = useRef();
  const {dimension} = useDimension();

  return (
    <Wrapper>
      <SlideInPanel
        zIndex={15}
        getInstance={inst => {
          panelRef.current = inst;
          props.getInstance({
            open: ({title, bottom, options}) => {
              panelRef.current.open(
                <PickerContent
                  title={title}
                  bottom={bottom}
                  options={options}
                />,
              );
            },
            close: () => {
              panelRef.current.close();
            },
          });
        }}
        position="bottom"
        style={{padding: 0, height: 'initial'}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  color: ${Color.mainDark};

  & > .title {
    ${fstyles.text16}
  }
  & > .options {
    display: flex;
    align-items: center;
    margin: 16px 0px;
  }
  & > .bottom {
    ${fstyles.text14}
  }
`;

export default BottomSlidePicker;
